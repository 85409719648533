<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      {{ actionstatus }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-text class="px-0 py-0 scoreTables mt-4">
      <v-data-table
        :headers="headers2"
        :items="students"
        :items-per-page="-1"
        :search="search"
        hide-details
        hide-default-footer
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td style="width: 1%">{{ props.item.index }}</td>
            <td>
               {{ props.item["DISPLAY_NAME"] }}
            </td>
            <td
              v-for="snum in semesterNumber"
              :key="'semesterscore-' + snum"
              style="width: 1%"
            >
              <input
                :id="props.item.id + snum"
                v-if="props.item.scores"
                :placeholder="props.item.id"
                type="number"
                v-model.number="props.item.scores[snum - 1].finalScore"
                @change="finalScoreChanged(props.item, snum)"
                style="width: 60px"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-container>
</template>
<script>
export default {
  name: "light-table-transparent",
  data() {
    return {
      actionstatus: null,
      search: "",
      snackbar: false,
      headers2: [
        {
          text: "No",
          align: "end",
          value: "index",
          sortable: true,
          width: "1%",
          fixed: true,
        },
        {
          text: "Нэр",
          align: "start",
          value: "firstName",
          sortable: true,
          fixed: true,
        },
      ],
    };
  },
  computed: {},
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
    semesterNumber: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async finalScoreChanged(student, semesterNumber) {
      console.log(student.ref.path, this.lesson.ref);
      this.lesson.ref
        .collection("studentList")
        .doc(student.id)
        .set({ scores: student.scores }, { merge: true })
        .then(() => {
          // student.ref.collection("finalGrades").doc(this.lesson.id).set(
          //   {
          //     scores: student.scores,
          //     semester: 1
          //   },
          //   { merge: true }
          // );

          this.actionstatus =
            student.firstName +
            ", " +
            student.scores[semesterNumber - 1].finalScore +
            " дүн амжилттай xадгалагдлаа.";
          this.snackbar = true;
          document.getElementById(student.id + semesterNumber).blur();
        });
    },
  },
  created() {
    for (var i = 0; i < this.semesterNumber; i++) {
      this.headers2.push({
        text: "Үнэлгээ-" + (i + 1),
        value: "score" + (i + 1),
        sortable: true,
      });
    }
  },
  watch: {
    students() {
      var counter = 0;
      if (this.students != null && this.students != undefined) {
        for (const student of this.students) {
          counter++;
          if (student.setupped == undefined) {
            student.index = counter;
            student.scores = [];
            if (this.semesterNumber > 0) {
              for (var k = 1; k <= this.semesterNumber; k++) {
                student.scores.push({ finalScore: 0, semester: k });
              }
            }
            student.setupped = true;

            this.lesson.ref
              .collection("studentList")
              .doc(student.id)
              .onSnapshot((doc) => {
                if (doc.exists) student.scores = doc.data().scores;
              });
          }
        }
      }
    },
  },
  mounted() {
    var counter = 0;
    if (this.students != null && this.students != undefined) {
      for (const student of this.students) {
        counter++;
        if (student.setupped == undefined) {
          student.index = counter;
          student.scores = [];
          if (this.semesterNumber > 0) {
            for (var k = 1; k <= this.semesterNumber; k++) {
              student.scores.push({ finalScore: 0, semester: k });
            }
          }
          student.setupped = true;
          this.lesson.ref
            .collection("studentList")
            .doc(student.id)
            .onSnapshot((doc) => {
              if (doc.exists) student.scores = doc.data().scores;
            });
        }
      }
    }
  },
};
</script>
<style>
.scoreTables td,
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>