<template>
  <v-container fluid v-if="lesson">
    <v-card class="border-radius-xl card-shadow">
      <div class="d-flex flex-row pb-0 px-4 py-4">
        <h4
          class="text-h4 my-auto text-typo font-weight-bold"
          style="white-space: nowrap"
        >
          {{ lesson.courseInfo.COURSE_NAME }},
          <span class="blue--text"> {{ _getClassName() }}</span>
        </h4>
      </div>
      <StudentsScoreTable
        v-if="students"
        :students="students"
        :lesson="lesson"
        :semesterNumber="1"
      ></StudentsScoreTable>
    </v-card>
  </v-container>
</template>
<script>
/*eslint-disable*/
import moment from "moment";
import myfunction from "@/components/global/myfunction.js";
import StudentsTable from "../Pages/Profile/Widgets/StudentsTable.vue";
import StudentsScoreTable from "./Widgets/StudentsScoreTable.vue";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  name: "smart-home",
  components: {
    StudentsScoreTable,
  },
  mixins: [myfunction],
  data() {
    return {
      loading: false,
      groupStudents: null,
      selectedTab: 0,
      loadingStudents: false,
      students: null,
      lesson: null,
      colIgnore: true,
      colWidth: 4,
      search: "",
      stQuery: null,
      stPath: null,
      stHeaderNames: null,
      stBodyNames: null,
    };
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...sync("*"),
  },
  methods: {
    _getClassName() {
      if (
        this.lesson &&
        this.lesson.classGroups &&
        this.lesson.classGroups[0].programData
      )
        return this.lesson.classGroups[0].programData.fullName;
      else return null;
    },
  },
  created() {
    this.stHeaderNames = [
      {
        text: "ID",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Хүйс",
        align: "start",
        sortable: true,
        value: "genderName",
      },
      {
        text: "Анги",
        align: "start",
        sortable: true,
        value: "className",
      },
    ];
    this.stBodyNames = ["name", "lastName", "genderName"];
  },
  mounted() {
    fb.db.doc(this.path).onSnapshot(async (doc) => {
      let lesson = doc.data();
      lesson.id = doc.id;
      lesson.ref = doc.ref;
      this.lesson = lesson;
      this.lesson.ref.collection("lesson-groups").onSnapshot((docs) => {
        lesson.lessonGroups = [];
        docs.forEach((doc) => {
          let lessonGroup = doc.data();
          lessonGroup.ref = doc.ref;
          lessonGroup.id = doc.id;
          if (
            lessonGroup.teacher &&
            lessonGroup.teacher.teacherId == this.userData.id
          ) {
            lessonGroup.students = null;
            lessonGroup.ref.collection("students").onSnapshot((docs) => {
              lessonGroup.students = [];
              this.groupStudents = [];
              docs.forEach((doc) => {
                let gstudent = doc.data();
                gstudent.ref = doc.ref;
                gstudent.id = doc.id;
                this.groupStudents.push(gstudent);
                lessonGroup.students.push(gstudent);
              });
            });
            lesson.lessonGroups.push(lessonGroup);
          }
        });
      });

      this.loadingStudents = true;
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where(
          "STUDENT_GROUP_ID",
          "==",
          lesson.classGroups[0].programData.STUDENT_GROUP_ID
        )
        .get()
        .then((docs) => {
          this.students = [];
          docs.docs.forEach((doc) => {
            let stud = doc.data();
            stud.id = doc.id;
            stud.ref = doc.ref;
            stud.DISPLAY_NAME = stud["LAST_NAME"]
              ? stud["LAST_NAME"][0] + ". " + stud["FIRST_NAME"]
              : stud["FIRST_NAME"];
            this.students.push(stud);
          });
          this.loadingStudents = false;
        });
    });
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
.xeelj-table-hcell {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  width: 100%;
}
.xeelj-table-cell {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  width: 100%;
  cursor: pointer;
}
.hoverbutton-:hover {
  color: green !important;
  font-weight: bold;
}
.hoverbutton--:hover {
  color: red !important;
  font-weight: bold;
}
</style>
